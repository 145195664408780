<template>
  <div v-if="resolution.width > 0" class="gridbox" :scope="resolution.width > resolution.notebook ? `` : `${resolution.width > resolution.mobile ? `tablet` : `mobile`}`">
    <component
      :is="MelodyItemListElement"
      v-for="(item, index) in items"
      :index="index"
      :item="item"
      :bg="`5xs`"
      :key="item.id"
    />
    <component
      :is="AdvertiseGameSquare"
      :max="items.length"
    />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import MelodyItemListElement from '~/component/game/melody/list-item.vue'
// import AdvertiseGameSquare from '~/component/advertise/game-square.vue'

export default {
  async setup () {

    // 글로벌 스토어
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      MelodyItemListElement.value = null
      AdvertiseGameSquare.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const MelodyItemListElement: any = shallowRef(null)
    const AdvertiseGameSquare: any = shallowRef(null)
    const loadComponent = async () => {
      const MelodyItemListElement_load = await import('~/component/game/melody/list-item.vue')
      const AdvertiseGameSquare_load = await import('~/component/advertise/game-square.vue')
      MelodyItemListElement.value = MelodyItemListElement_load.default || MelodyItemListElement_load
      AdvertiseGameSquare.value = AdvertiseGameSquare_load.default || AdvertiseGameSquare_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      resolution,

      // 컴포넌트
      MelodyItemListElement,
      AdvertiseGameSquare
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default () {
        return []
      }
    } as any
  },
  // components: {
  //   MelodyItemListElement,
  //   AdvertiseGameSquare
  // }
}
</script>